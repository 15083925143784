<template>
  <div v-if="isCalendarIntegrationLoading" class="loading-wrapper h-100">
    <progress-indicator
      :loading-label="staticText.calendarIntegrationLoadingLabel"
    />
  </div>
  <div v-else class="list-container w-100 px-5 text-slate">

    <div id="title" class="title pl-3">
      {{ staticText.integrations }} & {{ staticText.services }}
    </div>

    <b-alert :show="$route.query.showtext === 'true'"
             class="w-50"
             variant="secondary"
    >
      {{ staticText.information }}
    </b-alert>

    <!-- bao Swift -->
    <bao-swift-integration/>

    <!-- Services and integrations -->
    <services-and-integrations
      v-if="canUseCalendarIntegration"
      :integrationsLabel="staticText.calendarIntegrationLabel"
      :integrationsTooltipText="staticText.calendarIntegrationTooltipText"
      :integrations="calendarIntegrations"
      :loading="isCalendarIntegrationLoading"
      @toggle-integration="toggleCalendarIntegration"
    />
    <services-and-integrations
      :integrationsLabel="staticText.crmIntegrationLabel"
      :integrationsTooltipText="staticText.crmIntegrationTooltipText"
      :integrations="integrations"
      @toggle-integration="toggleIntegration"
      @open-o-auth-window="openOAuthWindow"
    />

    <!-- Active Services-->
    <div v-if="activeServicesAvailable"
         class="container-fluid mt-3"
    >
      <!-- Title -->
      <h5>
        {{ staticText.active }}
      </h5>

      <div class="flex-column align-items-start">

        <h6>{{ staticText.services }}</h6>

        <div v-for="(item, index) in activeServices"
             :key="index"
             class="d-flex justify-content-between border-top p-3"
        >

          <h5 class="mb-1 vertical-center">

            <component v-if="item.component"
                       :is="item.component"
                       v-on:setup="item.setup = $event"
            ></component>

            <span v-else
                  v-html="item.html"
            ></span>

            <span class="ml-2">{{ item.name }}</span>

          </h5>

          <h5 class="mr-2">

            <b-btn v-if="item.setup > 0"
                   variant="outline-success"
                   disabled>
              <i class="far fa-check-circle"></i>
            </b-btn>

            <b-btn v-if="item.setup === 0"
                   variant="outline-warning"
                   disabled
            >
              <i class="far fa-question-circle"></i>
            </b-btn>

            <b-btn v-if="item.setup < 0"
                   variant="outline-danger"
                   disabled
            >
              <i class="fas fa-exclamation-triangle"></i>
            </b-btn>

          </h5>

        </div>

      </div>
    </div>

    <!-- Inactive Services-->
    <div
      v-if="inactiveServicesAvailable"
      class="container-fluid"
    >
      <!-- Title -->
      <h5>
        {{ staticText.available }}
      </h5>

      <div class="flex-column align-items-start">
        <h6>
          {{ staticText.services }}
        </h6>

        <div v-for="(item, index) in inactiveServices"
             :key="index"
             class="d-flex justify-content-between border-top p-3"
        >
          <h5 class="mb-1 vertical-center">
            <span class="mr-2">
              <component v-if="item.component"
                         :is="item.component"
                         v-on:setup="item.setup = $event"
              ></component>
              <span v-else
                    v-html="item.html"
              ></span>
            </span>
            {{ item.name }}
          </h5>

          <h5>
            <i v-if="item.setup > 0"
               style="color: green"
               class="far fa-check-circle"
            ></i>

            <i v-if="item.setup === 0"
               style="color: gold"
               class="far fa-question-circle"
            ></i>

            <i v-if="item.setup < 0"
               style="color: red"
               class="fas fa-exclamation-triangle"
            ></i>
          </h5>

        </div>
      </div>
    </div>

    <!-- Modal for Service Requests -->
    <div v-if="requestedService && requestModal">
      <b-modal v-model="requestModal"
               title="Contact us!"
               ok-only
      >

        <img v-if="requestedService.extra_options && requestedService.extra_options.logo"
             :src="requestedService.extra_options.logo"
             class="w-50px"
             :alt="requestedService.name"
        />

        <i v-else-if="requestedService.extra_options && requestedService.extra_options.icon"
           :class="requestedService.extra_options.icon"
        ></i>

        <p class="my-4">
          To integrate {{ requestedService.name }} please contact us at
          <a href="mailto:request@bao-solutions.com">request@bao-solutions.com</a>
        </p>
      </b-modal>
    </div>

  </div>
</template>

<script>
import axios from "axios"
import UserManual from "../../base/UserManual"
import { mapActions, mapGetters } from "vuex"
import BaoSwiftIntegration from "./BaoSwiftIntegration"
import ServicesAndIntegrations from "./ServicesAndIntegrations"
import { exchangeAuthCodeForTokens, getUserCalendar, revokeOauthToken } from "@/apps/user/integrations/api"
import { Calendar_Items, CalendarTypeEnum } from "@/apps/user/integrations/constants"
import ProgressIndicator from "@/apps/base/ProgressIndicator.vue"

export default {
  name: "IntegrationList",
  components: {
    UserManual,
    BaoSwiftIntegration,
    ServicesAndIntegrations,
    ProgressIndicator
  },
  data () {
    return {
      axios,
      services: [
        {
          name: "LinkedIn Profiling",
          html: "<i style='color: royalblue' class=\"fab fa-linkedin\"></i>",
          setup: 1
        }
      ],
      integrations: [],
      calendarIntegrations: [],
      activeServices: [],
      inactiveServices: [],
      requestModal: false,
      requestedService: null,
      isCalendarIntegrationLoading: false,
      staticTextDefault: {
        integrations: "Integrations",
        services: "Services",
        active: "Active",
        available: "Available",
        information: "Click on “Connect” for the respective CRM. If you don’t have a CRM use the “bao CRM”",
        calendarIntegrationLabel: "Calendar Integrations",
        crmIntegrationLabel: "CRM Integrations",
        crmIntegrationTooltipText: "Connect your CRM or other services to be able to synchronize your (call) data.",
        calendarIntegrationTooltipText: "Link bao to your calendar to make it easier to select upcoming video meetings for recording.",
        calendarIntegrationLoadingLabel: "Loading ..."
      },
      baseUrl: window.location.origin
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      canUseCalendarIntegration: "auth/canUseCalendarIntegration"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    activeServicesAvailable () {
      return this.activeServices && this.activeServices.length > 0
    },
    inactiveServicesAvailable () {
      return this.inactiveServices && this.inactiveServices.length > 0
    }
  },
  mounted () {
    this.loadData()
    this.getCalendarOauthData()
    this.getGoogleOauthCode()
    this.getMicrosoftOauthCode()
  },
  methods: {
    ...mapActions({
      getUser: "auth/getUser"
    }),
    async getGoogleOauthCode() {
      // get auth code from url 
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      const authCode = url.searchParams.get('code');
      const state = url.searchParams.get('state')
      if (authCode && state===CalendarTypeEnum.GOOGLE) {
        this.isCalendarIntegrationLoading = true
        const payload = {
          auth_code: authCode,
          oauth_provider: "google",
          redirect_uri: `${this.baseUrl}/users/integrations`
        }
        await exchangeAuthCodeForTokens(payload)
        this.$router.push({ path: "/users/integrations" })
        this.getCalendarOauthData()
        this.isCalendarIntegrationLoading = false
      }
    },
    async getMicrosoftOauthCode() {
      // get auth code from url 
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      const authCode = url.searchParams.get('code');
      const state = url.searchParams.get('state')
      console.log("** microsoft oauth code:", authCode);
      console.log("** microsoft oauth code:", url);
      if (authCode && state===CalendarTypeEnum.OUTLOOK) {
        this.isCalendarIntegrationLoading = true
        const payload = {
          auth_code: authCode,
          oauth_provider: 'outlook',
          redirect_uri: `${this.baseUrl}/users/integrations`,
          scope: 'offline_access https://graph.microsoft.com/Calendars.ReadWrite'
        }
        await exchangeAuthCodeForTokens(payload)
        this.$router.push({path: "/users/integrations"})
        this.getCalendarOauthData()
        this.isCalendarIntegrationLoading = false
      }
    },
    getGoogleCalendarOauthUrl() {
      const googleClientID = process.env.VUE_APP_GOOGLE_CLIENT_ID
      const params = {
        client_id: googleClientID,
        redirect_uri: `${this.baseUrl}/users/integrations`,
        response_type: "code",
        ux_mode: "popup",
        scope: [
          "https://www.googleapis.com/auth/userinfo.email",
          "https://www.googleapis.com/auth/calendar.settings.readonly",
          "https://www.googleapis.com/auth/calendar.events"
        ].join(" "),
        access_type: "offline",
        prompt: "consent",
        state: 'google'
      }
      const url = new URL("https://accounts.google.com/o/oauth2/v2/auth")
      url.search = new URLSearchParams(params).toString()
      return url.toString()
    },
    getOutlookCalendarOauthUrl() {
      const microsoftClientID = process.env.VUE_APP_MICROSOFT_CLIENT_ID
      const params = {
        client_id: microsoftClientID,
        redirect_uri: `${this.baseUrl}/users/integrations`,
        response_type: "code",
        scope: 'offline_access openid email Calendars.ReadWrite User.Read',
        state: "outlook",
      }
      const url = new URL("https://login.microsoftonline.com/common/oauth2/v2.0/authorize");
      url.search = new URLSearchParams(params).toString();
      console.log('** url:', url);
      return url.toString()
    },
    isCrmIntegrationConnected (integration) {
      return integration.active && integration.logged_in
    },
    isCrmIntegrationDisconnected (integration) {
      return !integration.active
    },
    openOAuthWindow (url) {
      const that = this
      const windowName = "bao_oauth_login_" + Math.floor(Math.random() * 100)
      const sfWindow = window.open(url, windowName, "width=800,height=600")
      try {
        sfWindow.focus()
      } catch (e) {
        this.$refs.navWarningModal.show()
      }

      // set an interval to check if we've been redirected to our own domain, if that is the case, progress in the flow
      // the interval "hack" is required, as the listener otherwise is destroyed by the browser, as soon as we
      // redirect to salesforce
      let existingWin = null

      function checkChild () {
        try {
          existingWin = existingWin || window.open("", windowName)
          if (existingWin.closed) {
            clearInterval(timer)
            that.loadData()
          }
        } catch (e) {
          /* do nothing */
        }
      }

      const timer = setInterval(checkChild, 250)
    },
    async toggleCalendarIntegration (item, activate = true) {
      if (activate) {
        window.location.href = item.code_url
      } else {
        this.isCalendarIntegrationLoading = true
        const payload = {
          user_id: this.user.id,
          oauth_provider: item.key
        }
        await revokeOauthToken(payload)
        this.getCalendarOauthData()
        this.isCalendarIntegrationLoading = false
      }
    },
    toggleIntegration (item, activate = true) {
      const that = this
      if (item.code_url === "null") {
        this.requestModal = true
        this.requestedService = item
        return
      }
      return that.axios.get("/api/oauthlogin/" + item.key + (activate ? "/activate" : "/deactivate")).then(() => {
        if (activate) {
          that.openOAuthWindow(item.code_url)
        } else {
          that.loadData()
        }
      })
    },
    getCalendarOauthUrl (calendarType){
      if(calendarType==CalendarTypeEnum.GOOGLE){
        return this.getGoogleCalendarOauthUrl()
      } else if(calendarType==CalendarTypeEnum.OUTLOOK){
        return this.getOutlookCalendarOauthUrl()
      }
    },
    getCalendarIntegrationData () {
      const googleCalendar = Object.values(Calendar_Items).find(item => item.key === CalendarTypeEnum.GOOGLE);
      const outlookCalendar = Object.values(Calendar_Items).find(item => item.key === CalendarTypeEnum.OUTLOOK);
      
      const calendarOauthData = [
        {
          "integration_type": "calendar",
          "key": CalendarTypeEnum.GOOGLE,
          "name": googleCalendar.name,
          "status": "Not Connected",
          "icon": googleCalendar.icon,
          "code_url": this.getCalendarOauthUrl(CalendarTypeEnum.GOOGLE)
        },
        {
          "integration_type": "calendar",
          "key": CalendarTypeEnum.OUTLOOK,
          "name": outlookCalendar.name,
          "status": "Not Connected",
          "icon": outlookCalendar.icon,
          "code_url": this.getCalendarOauthUrl(CalendarTypeEnum.OUTLOOK)
        }
      ]
      return calendarOauthData
    },
    async getCalendarOauthData () {
      // todo - basant: refactor this for microsoft outlook integration
      const calendarData = await getUserCalendar({user_id: this.user.id})
      const calendarAuthData = this.getCalendarIntegrationData()
      this.calendarIntegrations = calendarAuthData
      calendarData.data.forEach(item => {
        const calendarItem = Object.values(calendarAuthData).find(calendar => calendar.key === item.calendar_type);
        if(!!calendarItem){
          calendarItem.status = item.is_active===true? 'Connected': 'Not Connected'
          calendarItem.auto_join_bot = item.auto_join_bot===true
          calendarItem.auto_join_bot_rule = item.auto_join_bot_rule
        }
        // this.calendarIntegrations = [calendarItem]
        this.calendarIntegrations = this.calendarIntegrations.map(
          item=>item.key===calendarItem.key?calendarItem: item
        )
      })
    },
    async getOAuthServices () {
      const response = await this.axios.get("/api/oauthlogin")
      this.integrations = response.data.results.map(item => {
        return {
          ...item,
          status: this.isCrmIntegrationConnected(item) ? "Connected" : this.isCrmIntegrationDisconnected(item) ? "Not Connected" : "Error"
        }
      })
    },
    loadData () {
      this.getUser()
      this.getOAuthServices()
    }
  }
}
</script>

<style scoped lang="scss">
.vertical-center {
  display: flex;
  align-items: center;
}

.w-50px {
  width: 50px;
}

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $background;
  backdrop-filter: blur(20px);
}
</style>
