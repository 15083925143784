<template>
    <div>
        <div class="d-flex">
            <div class="d-flex align-items-center">
                <b-btn 
                    @click="toggleAutoJoinSetting" class="ml-2 p-0 bg-transparent filter">
                    <i class="mx-2 fas fa-2x fa-toggle-on text-slate-80"
                    :class="{'toggle-btn': !autoJoin}"
                    />
                </b-btn>
                <span class="auto-join-text">
                {{ staticText.autoJoinBotText }}
                </span>
            </div>
            <div class="auto-setting-width d-flex">
                <div v-if="autoJoin"
                    :id="`auto-join-setting-btn-${calendarType}-20240926`"
                    @click="toggleAutoJoinSettingModal"
                    class="cursor-pointer auto-join-setting ml-1 d-flex align-items-center px-2"
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:height="'16'" v-bind:width="'16'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.324 7.23A8.98 8.98 0 013.22 3.97a.533.533 0 01.573-.143l2.04.726a1.065 1.065 0 001.403-.807l.39-2.125a.531.531 0 01.411-.423 9.11 9.11 0 013.78 0 .53.53 0 01.412.423l.39 2.125a1.063 1.063 0 001.402.807l2.04-.726a.533.533 0 01.574.142c.853.94 1.5 2.051 1.893 3.26a.528.528 0 01-.162.567l-1.653 1.397a1.054 1.054 0 000 1.614l1.653 1.397c.165.14.228.363.162.567a8.98 8.98 0 01-1.893 3.26.532.532 0 01-.573.142l-2.04-.726a1.065 1.065 0 00-1.403.807l-.39 2.125a.53.53 0 01-.413.423 9.101 9.101 0 01-3.779 0 .531.531 0 01-.412-.423l-.389-2.125a1.061 1.061 0 00-1.403-.807l-2.04.726a.532.532 0 01-.573-.142 8.98 8.98 0 01-1.893-3.26.528.528 0 01.162-.567l1.653-1.397a1.056 1.056 0 000-1.614L1.488 7.796a.528.528 0 01-.162-.567h-.002zM7.3 10a2.625 2.625 0 105.251 0 2.625 2.625 0 00-5.25 0z" fill="#35375B" opacity=".8"/></svg>
                </div>
                <b-tooltip
                    :target="`auto-join-setting-btn-${calendarType}-20240926`"
                    :title="staticText.calendarSettingTooltip"
                    triggers="hover"
                    placement="left"
                />
            </div>
          </div>
          <auto-join-setting-modal v-if="openAutoJoinModal"
            :autoJoinBotRule="autoJoinBotRule"
            :calendarType="calendarType"
            @saveAutoJoinSetting="saveAutoRuleSetting($event)"
            @close="toggleAutoJoinSettingModal"
          />
    </div>
</template>
<script>
import AutoJoinSettingModal from "@/apps/user/integrations/AutoJoinSettingModal.vue"
import { setAutoJoinBotSetting } from "@/apps/user/integrations/api"

export default{
    name: "AutoJoinSetting",
    components: {
        AutoJoinSettingModal
    },
    props: {
        autoJoinBot: {
            type: Boolean,
            default: false
        },
        autoJoinRule: {
            type: Object,
            default: {}
        },
        calendarType: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            openAutoJoinModal: false,
            autoJoin: false,
            autoJoinBotRule: {},
            staticTextDefault: {
                calendarSettingTooltip: "Co-Pilot Settings",
                autoJoinBotText: "Auto-joining Co-Pilot for your Video Meetings"
            }
        }
    },
    computed: {
        staticText () {
            return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
        },
    },
    methods: {
        toggleAutoJoinSetting() {
            this.autoJoin = !this.autoJoin
            const payload = {
                "auto_join_bot": this.autoJoin,
                "calendar_type": this.calendarType,
                "auto_join_bot_rule": {}
            }
            setAutoJoinBotSetting(payload)
        },
        saveAutoRuleSetting(data) {
            console.log('** saveAutoRuleSetting:', );
            this.autoJoinBotRule = data.auto_join_bot_rule
            setAutoJoinBotSetting(data)
        },
        toggleAutoJoinSettingModal() {
            this.openAutoJoinModal = !this.openAutoJoinModal
        }
    },
    mounted (){
        console.log('** mounted 22 saveAutoRuleSetting:', );
        this.autoJoin = this.autoJoinBot
        this.autoJoinBotRule = this.autoJoinRule
    }
}
</script>
<style lang="scss" scoped>

.toggle-btn {
    color: #D4D5DC;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.auto-join-text {
    color: #2A2D52;
    font-size: 12px;
    line-height: 14px;
}
.auto-join-setting{
    background-color: #2A2D520F;
    border-radius: 12px;
}
.auto-setting-width{
    width: 36px;
}
</style>